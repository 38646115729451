/* cyrillic-ext */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gTD_u50.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3g3D_u50.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gbD_u50.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gfD_u50.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gTD_u50.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3g3D_u50.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gbD_u50.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gfD_u50.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gTD_u50.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3g3D_u50.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gbD_u50.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gfD_u50.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* @import "https://code.getmdl.io/1.3.0/material.indigo-pink.min.css"; */

/**
 * material-design-lite - Material Design Components in CSS, JS and HTML
 * @version v1.3.0
 * @license Apache-2.0
 * @copyright 2015 Google, Inc.
 * @link https://github.com/google/material-design-lite
 */

html {
  color: rgba(0, 0, 0, .87);
  font-family: 'Montserrat', sans-serif;
  font-size: .9rem;
  color: #2E2E2E;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  overflow: hidden !important;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none
}

::selection {
  background: #b3d4fc;
  text-shadow: none
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0
}

textarea {
  resize: vertical
}

.browserupgrade {
  margin: .2em 0;
  background: #ccc;
  color: #000;
  padding: .2em 0
}

.hidden {
  display: none !important
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto
}

.invisible {
  visibility: hidden
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table
}

.clearfix:after {
  clear: both
}

body {
  width: 100%;
  min-height: 100%
}

*[hidden] {
  display: none !important
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-family: 'Montserrat', "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -.02em;
  opacity: .54;
  font-size: .6em
}

h1 {
  font-size: 56px;
  line-height: 1.35;
  letter-spacing: -.02em;
  margin: 24px 0
}

h1,
h2 {
  font-family: 'Montserrat', "Helvetica", "Arial", sans-serif;
  font-weight: 400
}

h2 {
  font-size: 45px;
  line-height: 48px
}

h2,
h3 {
  margin: 24px 0
}

h3 {
  font-size: 34px;
  line-height: 40px
}

h3,
h4 {
  font-family: 'Montserrat', "Helvetica", "Arial", sans-serif !important;
  font-weight: 600 !important;
}

h4 {
  font-size: 24px;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  margin: 24px 0 16px
}

h5 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: .02em
}

h5,
h6 {
  font-family: 'Montserrat', "Helvetica", "Arial", sans-serif !important;
  /* margin: 24px 0 16px */
}

h6 {
  font-size: 16px;
  letter-spacing: .04em
}

h6,
p {
  font-weight: 400;
}

p {
  line-height: 1.5 !important;
  letter-spacing: 0;
}

a {
  color: rgb(255, 64, 129);
  font-weight: 500
}

blockquote {
  font-family: 'Montserrat', "Helvetica", "Arial", sans-serif;
  position: relative;
  font-size: 24px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.35;
  letter-spacing: .08em
}

blockquote:before {
  position: absolute;
  left: -.5em;
  content: 'â€œ'
}

blockquote:after {
  content: 'â€';
  margin-left: -.05em
}

mark {
  background-color: #f4ff81
}

dt {
  font-weight: 700
}

address {
  font-size: 12px;
  line-height: 1;
  font-style: normal
}

address,
ul,
ol {
  font-weight: 400;
  letter-spacing: 0
}

@-webkit-keyframes indeterminate1 {
  0% {
    left: 0%;
    width: 0%
  }

  50% {
    left: 25%;
    width: 75%
  }

  75% {
    left: 100%;
    width: 0%
  }
}

@keyframes indeterminate1 {
  0% {
    left: 0%;
    width: 0%
  }

  50% {
    left: 25%;
    width: 75%
  }

  75% {
    left: 100%;
    width: 0%
  }
}

@-webkit-keyframes indeterminate2 {

  0%,
  50% {
    left: 0%;
    width: 0%
  }

  75% {
    left: 0%;
    width: 25%
  }

  100% {
    left: 100%;
    width: 0%
  }
}

@keyframes indeterminate2 {

  0%,
  50% {
    left: 0%;
    width: 0%
  }

  75% {
    left: 0%;
    width: 25%
  }

  100% {
    left: 100%;
    width: 0%
  }
}

@-webkit-keyframes border-expand {
  0% {
    opacity: 0;
    width: 0
  }

  100% {
    opacity: 1;
    width: 100%
  }
}

@keyframes border-expand {
  0% {
    opacity: 0;
    width: 0
  }

  100% {
    opacity: 1;
    width: 100%
  }
}

body {
  margin: 0
}

.styleguide-demo h1 {
  margin: 48px 24px 0
}

.styleguide-demo h1:after {
  content: '';
  display: block;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, .5);
  margin-top: 24px
}

.styleguide-demo {
  opacity: 0;
  transition: opacity .6s ease
}

.styleguide-masthead {
  height: 256px;
  background: #212121;
  padding: 115px 16px 0
}

.styleguide-container {
  position: relative;
  max-width: 960px;
  width: 100%
}

.styleguide-title {
  color: #fff;
  bottom: auto;
  position: relative;
  font-size: 56px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -.02em
}

.styleguide-title:after {
  border-bottom: 0
}

.styleguide-title span {
  font-weight: 300
}

.demosLoaded .styleguide-demo {
  opacity: 1
}

iframe {
  display: block;
  width: 100%;
  border: none
}

iframe.heightSet {
  overflow: hidden
}

.demo-wrapper {
  margin: 24px
}

.demo-wrapper iframe {
  border: 1px solid rgba(0, 0, 0, .5)
}

/* @import "https://fonts.googleapis.com/icon?family=Material+Icons"; */

/* fallback */

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v50/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  /* -webkit-font-feature-settings: 'liga'; */
  -webkit-font-smoothing: antialiased;
}

/* @import "@angular/material/prebuilt-themes/indigo-pink.css"; */

/* @import "bootstrap-tagsinput/dist/bootstrap-tagsinput.css"; */

/*Custom Scroll*/

/* width */

::-webkit-scrollbar {
  width: 0.45vw;
  height: 0.5vw;
  cursor: pointer;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #DEDEDE;
  border-radius: 10px;
  display: none;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #DEDEDE;
  /* border-radius: 0.5vw; */
  cursor: pointer;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #888888;
}

body {
  font-family: 'Montserrat', "Helvetica", "Arial", sans-serif !important;
  /* font-size: 1em;
    font-size: calc(0.5vw + 0.5vh); */
  font-size: 0.8vw !important;
  color: #2E2E2E;
  overflow: hidden;
  padding: 0%;
  height: 100%;
  margin: 0%;
  line-height: 1.5 !important;
}

/*a,
span,
div,
td,
th label,
ul,
li,
select {
  font-size: 0.835vw !important;
} */

a,
a:hover {
  text-decoration: none;
  outline: none;
}

p {
  margin-bottom: 0% !important;
}

label {
  margin-bottom: 0%;
  font-weight: 600;
}

.dn {
  display: none !important;
}

.cdk-overlay-container {
  z-index: 9999999 !important;
}

.cdk-overlay-container .page-content {
  margin: 0.7vw;
}

.highlightorange {
  color: #F47625;
}

.highlightblue {
  color: #0A4087;
}

.btn {
  cursor: pointer;
  font-size: 0.8vw !important;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem transparent;
}

h4 {
  font-size: 1.3vw !important;
  line-height: 1.5 !important;
}

.pageheader {
  border-bottom: 0.07vw solid #a2a2a254;
}

.pageheadtitle {
  display: inline-block;
}

.pageheadtitle h4 {
  color: #0A4087;
  padding: 0.2vw 0 0.7vw 0;
  margin: 0;
  font-size: 1.57vw !important;
}

select {
  width: fit-content;
}

select:focus {
  outline: none;
}

.form-control[readonly] {
  background: transparent;
}

td.details-control .rightArrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  border-top: 0.5vw solid transparent;
  border-bottom: 0.5vw solid transparent;
  border-left: 0.5vw solid #10458b;
  border-radius: 1vw;
  cursor: pointer;
  -moz-transition: all .2s linear;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

td.details-control {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

tr.details td.details-control .rightArrow {
  -moz-transition: all .2s linear;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/*Orange Button*/

.orangebtn {
  background: #F47625;
  color: #ffffff;
  letter-spacing: .5px;
}

.createstudybtn {
  background: #F47625;
  color: #fff;
  letter-spacing: .5px;
  line-height: 1.5;
  border-radius: 7px;
}

/*Floating Right*/

.rightfloat {
  text-align: right;
  float: right;
}

/*Floating Left*/

.leftfloat {
  text-align: left;
  float: left;
}

/*Margin-top*/

.mtop_1 {
  margin-top: 1vh;
}

/*Margin-bottom*/

.mbot_1 {
  margin-bottom: 1vh;
}

.mbot_Preview{
  /* margin-bottom: 0.25vw; */
}

/*Content Layout End*/

.padbot_1 {
  padding-bottom: 1%;
}
.padbot_1_5 {
  padding-bottom: 1.5%;
}

/*Safari Browser*/

/* code from app.css starts*/

.bootstrap-tagsinput {
  height: fit-content;
  border-radius: 0px !important;
  border-color: #DCDCDC !important;
  width: 93%;
  height: fit-content;
  color: #2E2D2D !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bootstrap-tagsinput input {
  color: #2E2D2D !important;
}

.bootstrap-tagsinput .tag {
  color: #2E2D2D !important;
  background: #eee;
  padding: .5% 2%;
  font-size: .9em;
}

.studySearchMainDiv .bootstrap-tagsinput {
  width: 100% !important;
  background-color: transparent;
  border: none;
  box-shadow: none;
  display: block;
  color: transparent;
  cursor: text;
  height: 2.2vw;
  padding: 0.33vw 0.47vw !important;
  line-height: 1.5vw !important;
}

.filterdropdown {
  padding: 2% 5%;
  width: 100%;
}

.filterdrop {
  padding: 23% 1% 15% 0%;
  border-radius: 0%;
  border: 1px solid #DCDCDC;
}

.dropdown-toggle::after {
  color: #10458B;
  border: 0px;
  padding: 0.3vw 0.1vw;
  font: 0.6vw/1 FontAwesome;
  content: "\f0d7" !important;
  font-weight: 600;
  border-top: 0px !important;
}

/* code from app.css ends */

/* success and error msg css start */

.errormsgs {
  margin: 0%;
  font-family: 'Montserrat', "Helvetica", "Arial", sans-serif;
  font: small-caption;
  padding: 0rem 0rem .9rem 0rem;
  letter-spacing: .03em;
  color: #f00;
  font-weight: 500;
}

.successmsgs {
  margin: 0%;
  font-family: 'Montserrat', "Helvetica", "Arial", sans-serif;
  font: small-caption;
  padding: .3rem 0rem .9rem 0rem;
  letter-spacing: .03em;
  color: #028600;
  font-weight: 500;
}

/* success and error msg css end */

/*Annotation Comment box start*/

.anotatepop {
  position: absolute;
  background: #fff;
  box-shadow: 1px 1px 3px 1px #d0d0d0;
  width: 20vw;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
}

.anotatepop .iconActions {
  margin: 0vw;
  background: #d3d3d3bf;
}

.anotatepop .iconActions .annoInfo {
  cursor: pointer;
  color: rgb(78, 78, 78);
  font-size: 0.9vw;
  margin: 0 0.25vw;
}

.anotatepop .annoCreatedBy {
  margin: 0.15vw 0.25vw 0;
  border: 1px solid lightgray;
  border-radius: 0.15vw;
  width: 88%;
}

.anotatepop .annoCreatedBy span {
  display: inline-block;
  font-size: 0.75vw !important;
  padding: 0.15vw 0.25vw;
}

.anotatepop .annoColorPicker {
  width: 9%;
}

.anotatepop .annoColorPicker input {
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  width: 1.5vw;
  height: 1.6vw;
}

.anotatepop .annoCreatedByTitle {
  background-color: lightgray;
  font-weight: bold;
}

.anotatepop .annoCommentDiv {
  margin: 0.25vw;
}

.anotatepop textarea,
.anotatepop textarea:focus {
  background: rgb(255 255 255 / 30%);
  resize: none;
  max-height: 10vw;
  overflow: auto;
}

.anotatepop .annoAreaParameterDiv span {
  font-size: 0.7vw !important;
}

.anotatepop .annoAreaParameterDiv {
  margin: 0.25vw 0.5vw;
}

.anotatepop .errormsgs {
  padding: 0;
}

.anotatepop .savingInProgress {
  color: #EA8823 !important;
}

/* .anotatepop textarea {
  resize: none;
  box-shadow: 0px 1px 2px 1px #d3d3d3;
  outline: none;
  border: 0;
  border-radius: 3px;
} */

::-webkit-color-swatch {
  border-color: transparent !important;
  border-radius: 10%;
}

.area_perim {
  font-size: smaller;
  letter-spacing: .03rem;
  display: inline-flex;
}

.area_perim label {
  font-size: .7vw !important;
}

.area_perim span {
  font-size: .7vw !important;
}

.icon_del {
  cursor: pointer;
}

.icon_del_bokmark i:hover {
  color: #f47625;
}

.icon_bookmark {
  color: #f47625 !important;
}

.viewer_email_annotatepop {
  text-align: left;
  color: #a2a2a2;
  letter-spacing: .03vw;
  font-size: .7vw !important;
}

.savebtn {
  padding: .55rem .7rem;
}

.anotatepop_after {
  position: absolute;
  /* top: 5rem; */
  padding: 1rem 1rem 0.5rem 1rem;
  background: #fff;
  /* left: 17rem; */
  border-radius: .5rem;
  box-shadow: 1px 1px 3px 1px #d0d0d0;
}

.anotatepop_after textarea {
  resize: none;
}

/*Annotation Comment box end*/

.filterbtn {
  margin: 0%;
  padding: 1% 1%;
  width: 100%;
}

.filterbtn div {
  padding: .7vw .6vw;
}

.filterbtn button {
  float: right;
  margin-left: 2%;
}

.btn-default {
  background: #D6DFF2;
  padding: .59vw .9vw;
  border: 0px;
  border-radius: .3vw;
  outline: none;
}

.btn-default:active {
  border: 0px;
  outline: none;
}

.btn-default:focus {
  border: 0px;
  outline: none;
}

.warningcancel {
  font-size: .9rem;
  padding: .47rem .9rem;
}

.searchbtn {
  background: #F47625;
  color: #ffffff;
  /* padding: 1% 3%;
  width: fit-content; */
}

.closebtn {
  background: #fff;
  color: #4a4a4a !important;
  border: 1px solid #eee;
}

.orangeText {
  color: #F47625;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 600;
}

.boldText {
  font-weight: bold;
}

#p_study_table_wrapper .dataTables_filter,
#p_project_table_wrapper .dataTables_filter,
#user_table_wrapper .dataTables_filter {
  display: none !important;
}

/* table.dataTable thead .sorting {
  background-image: none !important;
} */

/* table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  bottom: 11px !important;
} */

table.dataTable thead th,
table.dataTable thead td {
  text-align: left;
  color: #ffffff;
  padding: .6vw;
  font-size: 0.9vw;
  background: #10458B;
  padding-right: 20px !important;
}

table.dataTable thead th.sorting::before,
table.dataTable thead th.sorting::after {
  opacity: 0 !important;
}

table.dataTable thead th.sorting.sorting_desc::after,
table.dataTable thead th.sorting.sorting_asc::before {
  opacity: 0.125 !important;
}

table.dataTable thead th:before,
table.dataTable thead th:after {
  right: 0px;
}

/* table height */

/* .dataTables_scrollHeadInner table.dataTable thead tr th,
#dataSlidesTableHeader thead tr th {
  height: 2vw !important;
} */

table.dataTable tbody tr {
  height: 3vw !important;
}

/* table.dataTable thead td {
  color: #2E2E2E;
}

#p_study_table thead th {
  color: #ffffff;
  font-size: 1.1em;
  text-align: left;
} 

#p_study_table tbody {
  overflow-x: hidden;
  overflow-y: auto;
}

#p_study_table tbody td {
  color: #2E2D2D;
}

.tabledata {
  margin: 0%;
  font-size: .9rem;
} */

.imgicontable {
  padding: .1vw .3vw;
}

.imgicontable i {
  font-size: 1.1em;
  text-decoration: none;
  color: #bfbcbc;
  padding: .1vw .3vw;
  cursor: pointer;
  display: contents;
}

table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
  background-color: #EFF6FF !important;
  box-shadow: 0px 3px 4px -3px #212121 !important;
}

table.dataTable.display tbody td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.imgicontable:hover i {
  color: #F47625;
}

.detailConsultationIconi {
  font-size: 1.1em;
  text-decoration: none;
  color: #F47625;
}

/*Custom Radio btn for screenshot popup*/

.form-check-inline .radio {
  position: relative;
  float: left;
  clear: left;
  display: block;
  padding-left: 1.35vw;
  line-height: 1.3;
  color: #666;
  cursor: pointer;
}

.form-check-inline .radio:before {
  background: #fff;
  content: "";
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  width: 1.1vw;
  height: 1vw;
  border: 0.06vw solid #666;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
}

.form-check-inline input[type="radio"] {
  display: none;
}

.form-check-inline input[type="radio"]:checked+label:before {
  content: "\2022";
  text-align: center;
  line-height: 0.43vw;
  font-size: 2.8vw;
  color: #f47625;
  border: 0.06vw solid #f47625;
}

.filenm input {
  border: 1px solid #dcdcdc;
  padding: .3vw .5vw;
  width: 20vw;
}

#screenshot-editor-button-close {
  color: #fff;
  opacity: 1;
}

.padding_tabs {
  padding: 0.6vw 0vw;
  margin: 0;
}

.rs-handle {
  margin: -0.5vw 0px 0px 0.125vw !important;
  border-color: transparent #EA8823 !important;
  border-style: solid !important;
  border-width: 0.5vw 0.5vw 0.5vw 0px !important;
  height: 0px !important;
  width: 0px !important;
  background-color: transparent !important;
}

.rs-bar .rs-seperator {
  display: none !important;
}

.rs-bg-color {
  background-color: #909090 !important;
}

.rs-tooltip-text {
  font-weight: bold;
  margin-left: 0px !important;
  transform: translateX(-50%);
  cursor: pointer !important;
}

.rs-tooltip-text:hover {
  color: #EA8823;
}

.mat-dialog-container {
  padding: 0 !important;
  overflow-x: hidden !important;
}

.modal-header {
  background: #10458B;
  padding: 0.5vw .5vw;
  margin: 0%;
  color: #fff;
}

.modal-body {
  padding: 1vw;
}

.modal-footer {
  padding: 1% !important;
  border-top: none !important;
}

.modal#screenshot-editor {
  z-index: 999999999;
}

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.textellipsis_td {
  max-width: 11vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.close {
  outline: none !important;
}

.disablebtn_func {
  pointer-events: none;
  background: #E8E8E8;
  color: #A2A2A2;
}

.technidropdown_list {
  background: #F47625;
  padding: 0.5vw 0.8vw;
  color: #fff;
}

.technidropdown_list:hover {
  color: #fff;
}

.technidropdown_list:active {
  color: #fff;
}

.technidropdown .dropdown-toggle::after {
  color: #fff !important;
  vertical-align: 0 !important;
}

.btnscreenshot {
  display: contents;
}

.screenshotfootersearch {
  margin-right: .5rem;
  margin-left: .5rem;
  width: 5rem;
}

.icon_del_bokmark {
  text-align: right;
  float: right;
  vertical-align: bottom;
}

.icon_del_bokmark i {
  margin-right: .9rem;
  text-align: right;
  float: right;
  vertical-align: bottom;
  color: #bfbcbc;
  cursor: pointer;
}

/* .mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label {
  font-size: 1.5rem;
  font-weight: 600;
} */

.ellipsisApplied {
  padding-right: 0.5vw !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* added by mrunali on 15/07/2020*/

.margin_top_1_rem {
  margin-top: 1.1vw;
}

.snackbar {
  position: absolute;
  top: 2vw;
  margin-left: -12.5% !important;
  word-break: break-word;
}

/* Tab css */

.tabbar {
  padding: 0.7em !important;
}

.borderbefore {
  border-left: 0.2vw solid #eee;
  /* padding-left: 1%; */
  text-align: left;
}

h6.tabliketitle {
  /* font-weight: 600; */
  color: #717171;
  margin: 0%;
  font-size: 1.17vw;
  line-height: 2;
  letter-spacing: 0px;
  cursor: pointer;
  padding-left: .1vw;
  text-align: center;
}

h6.tabliketitle span {
  font-size: 1.17vw !important;
}

h6.active {
  color: #4B4B4B;
  border-bottom: 0.2vw solid #F47625;
  font-weight: 500;
}

.marginZero {
  margin: 0 !important;
}

.cssTooltip {
  cursor: pointer;
}

.cssTooltip .cssTooltipText {
  visibility: hidden;
  width: auto;
  min-width: 12vw;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 0.4vw;
  padding: 0.3vw 1vw;
  position: absolute;
  z-index: 1;
}

.cssTooltip:hover .cssTooltipText {
  visibility: visible;
}

.mandatory {
  color: red;
}

.xsDiv {
  width: 7vw;
}

.sDiv {
  width: 10.5vw;
}

.mDiv {
  width: 14vw;
}

.lDiv {
  width: 17.5vw;
}

.xlDiv {
  width: 21vw;
}

.inlineBlock {
  display: inline-block;
  margin-right: 3vw;
}

.keyHeader {
  color: #f47625;
  font-weight: 600;
}

.keyTag {
  color: #113356;
  font-weight: 600;
  font-size: 0.7vw !important;
}

.sText {
  font-size: 0.7vw !important;
}

.rowAdditionalInfo .slideAdditionalInfo:not(:last-child) {
  margin-bottom: 0.5vw;
}

/* #loaderDialog {
  padding: 0vw !important;
  background-color: rgb(255 255 255 / 70%);
  min-width: 12vw;
} */

.dataTables_wrapper .dataTables_filter input {
  height: 1.5vw !important;
  outline-offset: 1px;
  outline-color: #b5b5b5;
  outline-width: 0px;
}

.tooltip-inner {
  max-width: 600px !important;
  z-index: 9999999 !important;
}

.tooltip.bs-tooltip-left {
  z-index: 9999999 !important;
}

.spinner-class {
  margin-right: 0.5vw;
  width: 1vw;
  height: 1vw;
}

table {
  border-collapse: separate;
  border-collapse: collapse;
  /* Fallback for IE <=7. */
  border-spacing: 0;
  table-layout: fixed;
}

/* table tr:first-child th:first-child {
  -moz-border-radius: 6px 0 0 0;
  -webkit-border-radius: 6px 0 0 0;
  border-radius: 6px 0 0 0;
}

table tr:first-child th:last-child {
  -moz-border-radius: 0 6px 0 0;
  -webkit-border-radius: 0 6px 0 0;
  border-radius: 0 6px 0 0;
}

table tr:first-child th:only-child {
  -moz-border-radius: 6px 6px 0 0;
  -webkit-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0;
} */

table tbody td {
  border-top: 0;
  border-top: inherit;
  /* Fallback for IE <=7. */
}

/* table tr:last-child td:first-child {
  -moz-border-radius: 0 0 0 6px;
  -webkit-border-radius: 0 0 0 6px;
  border-radius: 0 0 0 6px;
}

table tr:last-child td:last-child {
  -moz-border-radius: 0 0 6px 0;
  -webkit-border-radius: 0 0 6px 0;
  border-radius: 0 0 6px 0;
}

table tr:last-child td:last-child:hover {
  -moz-border-radius: 0 0 6px 0;
  -webkit-border-radius: 0 0 6px 0;
  border-radius: 0 0 6px 0;
}

table tr:last-child td:only-child {
  -moz-border-radius: 0 0 6px 6px;
  -webkit-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
} */

.no_m_p {
  margin: 0;
  padding: 0;
}

#p_study_table tbody tr:hover {
  background-color: #EFF6FF !important;
  box-shadow: inset 0px -2px 7px 0px #21212121 !important;
  -webkit-box-shadow: inset 0px -2px 7px 0px #21212121 !important;
  -moz-box-shadow: inset 0px -2px 7px 0px #21212121 !important;
}

#user_table tbody tr:hover {
  background-color: #EFF6FF !important;
  box-shadow: inset 0px -2px 7px 0px #21212121 !important;
  -webkit-box-shadow: inset 0px -2px 7px 0px #21212121 !important;
  -moz-box-shadow: inset 0px -2px 7px 0px #21212121 !important;
}

.form-control:focus {
  border-color: #ced4da !important;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%) !important;
}

/* Popup width */

.popupwidth {
  max-width: 33vw;
}

.table-responsive {
  padding-top: .89vw;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 30px #f5f5f5d6 inset !important;
}

.disableFunction {
  opacity: 0.7;
  pointer-events: none;
}

.tempHide {
  display: none !important;
}

.annoPopOverDiv {
  background: rgba(255, 255, 255, 0.9);
  max-width: 30vw !important;
  z-index: 999999;
  box-shadow: 0 0.25vw 0.5vw 0 rgb(0 0 0 / 20%), 0 0.25vw 0.5vw 0 rgb(0 0 0 / 20%);
}

.annoPopOverDiv[x-placement^=top] {
  top: 8px !important;
}

.annoPopOverDiv[x-placement^=left] {
  left: 8px !important;
}

.annoPopOverDiv[x-placement^=right] {
  left: -8px !important;
}

.annoPopOverDiv[x-placement^=bottom] {
  top: -8px !important;
}

.annoPopOverDiv .popover-body {
  padding: 0vw;
}

.labelbg {
  /* background: #fff; */
  padding: 0 0.3vw;
}

.cdk-focused,
.cdk-mouse-focused,
button:focus {
  outline: 0 !important;
}

/* button:focus .mat-calendar-body-cell-content::before {
  margin: 0 !important;
} */

.commonMatFormField {
  display: block;
  font-size: 0.7vw;
  width: 100%;
}

.commonMatFormField .mat-form-field-wrapper {
  margin: 0;
  padding: 0;
}

.commonMatFormField .mat-form-field-wrapper .mat-form-field-flex {
  border: none;
}

.commonMatFormField .mat-form-field-subscript-wrapper {
  padding-top: 2vh !important;
}

.dispFlex {
  display: flex;
  align-items: center;
}

.tileTitle {
  color: #10458b;
  font-size: 0.75vw;
  font-weight: 600;
}

@media screen and (max-width: 1200px) {
  mat-slide-toggle.matResponsiveToggle {
    transform: scale(0.75);
  }
}